import {Injectable}     from '@angular/core';
import {FormGroup}      from '@angular/forms';
import {manualOverride} from '../../interfaces/form-map.interface';
import {NgbDate}        from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class FormMapService
{
    constructor()
    {
    }

    public mapResponseToForm(data: any, formGroup: FormGroup, overrides: manualOverride = {}): void
    {
        // Helper function to set value by path
        const setValueByPath = (path: string, value: any, baseFormGroup: FormGroup) => {
            let splitPaths = path.split('.');
            let dateInput = splitPaths.length > 1 ? splitPaths[1].split('/') : null;
            let control;

            if (splitPaths.length > 1) {
                control = baseFormGroup.get(splitPaths[0])?.get(splitPaths[1].split('/')[0]);
            } else {
                control = baseFormGroup.get(path);
            }

            if (control) {
                //possibly look to add .id to the below however make it dynamically passed through the overrides
                //This references the id, so if status object has id it will map to that
                if (dateInput != null && dateInput.length > 1) {
                    let newDate = value.split('-')
                    let ngbdate = new NgbDate(Number(newDate[0]), Number(newDate[1]), Number(newDate[2].split('T')[0]))
                    control.setValue(ngbdate);
                } else {
                    control.setValue(value.id);
                }
            } else {
                console.warn(`Control not found for path: ${path}`);
            }
        };

        // Helper function to recursively map data to the form, considering overrides
        const recursiveMap = (currentData: any, currentFormGroup: FormGroup, parentPath: string[] = []) => {
            Object.keys(currentData).forEach(key => {
                const currentPath = [...parentPath, key];
                const dataKeyPath = currentPath.join('.');
                const overridePath = overrides[dataKeyPath];
                const value = currentData[key];

                if (overridePath) {
                    // If there's an override for this path, set the value accordingly
                    setValueByPath(overridePath, value, formGroup);
                } else {
                    // No override, proceed with default logic
                    const formControl = currentFormGroup.get(key);
                    if (formControl instanceof FormGroup) {
                        // If it's a FormGroup, recurse into it
                        recursiveMap(value, formControl, currentPath);
                    } else if (formControl) {
                        // If it's a FormControl, set its value
                        formControl.setValue(value);
                    } else {
                        // Attempt to handle nested paths in overrides not directly mapped
                        const nestedKeys = Object.keys(currentFormGroup.controls).filter(k => currentFormGroup.get(k) instanceof FormGroup);
                        nestedKeys.forEach(nestedKey => {
                            const nestedGroup = currentFormGroup.get(nestedKey);
                            if (nestedGroup && nestedGroup instanceof FormGroup) {
                                recursiveMap({[key]: value}, nestedGroup, [nestedKey]);
                            }
                        });
                    }
                }
            });
        };

        // Start the recursive mapping with the top-level data and form group
        recursiveMap(data, formGroup);
    }

    public returnControlValue(form: FormGroup, control_one: string, control_two?: string): any
    {
        if (control_two) {
            return form.controls[control_one].get(control_two)?.value;
        }

        return form.controls[control_one].value;
    }

    public setControlValue(form: FormGroup, value: any, control_one: string, control_two?: string): any
    {
        if (control_two) {
            return form.controls[control_one].get(control_two)?.setValue(value);
        }

        return form.controls[control_one].setValue(value);
    }
}
