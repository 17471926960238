import {Injectable}                                                   from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService}                                                  from "../services/auth.service";
import {catchError, take}                                             from "rxjs/operators";
import {map, Observable, of, tap}                                     from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AccountIntegrationGuard
{
    constructor(
        private authService: AuthService,
        private router: Router,
    )
    {
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree>
    {
        return this.authService.tenantHasBeenOnboarded().pipe(
            take(1),
            map(isOnboarded => {
                if (isOnboarded) {
                    return true;
                } else {
                    return this.router.createUrlTree(['/app/accounts/onboarding'], {
                        queryParams: {redirectUrl: state.url}
                    });
                }
            }),
            catchError(() => {
                return of(this.router.createUrlTree(['/app/accounts/onboarding']));
            })
        );
    }

    // private async check(redirectUrl: string): Observable<boolean | UrlTree>
    // {
    //     if (!this.authService.tenantHasBeenOnboarded()) {
    //         // NOTE: Maybe just navigate them to the onboarding page, and have them connect via that rather than having a full modal doing the same thing?
    //         const modalRef = this.modalService.open(AccountsConnectionModalComponent, {
    //             centered: true,
    //             fullscreen: true,
    //             keyboard: false,
    //             beforeDismiss: () => {
    //                 return false;
    //             }
    //         });
    //
    //         modalRef.result.then(result => {
    //             console.log(result);
    //         });
    //     }
    // }
}
