import {Component, ElementRef, Inject, Renderer2} from '@angular/core';
import {AuthService}                              from "../../services/auth.service";
import { VehicleStatuses } from '../../../models/enums/vehicle-statuses.enum';
import {RouteInfo} from "../../sidebar.metadata";
import {UserStatuses} from "../../../models/enums/user-statuses.enum";
import {NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import { ThemeService }                                          from '../../../shared/services/theme.service';
import {DOCUMENT}      from "@angular/common";
import {faMoon, faSun} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrl: './admin-header.component.scss'
})
export class AdminHeaderComponent {
    public currentTheme: string = '';
    public lastLoggedInAt: string = 'TODO: Add this to user table and update each time log in.';
    public vehicleMenuIsCollapsed: boolean = true;
    public menuItems: any[] = this.getSidebarLinks(false);
    public toggleClass: string|null = null;
    protected readonly faMoon = faMoon;
    protected readonly faSun = faSun;

    public constructor(
        @Inject(DOCUMENT) public document: Document,
        public authService: AuthService,
        public themeService: ThemeService,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private router: Router
    )
    {
        this.router.events.subscribe(event => {
           if (event instanceof NavigationStart) {
               // TODO: Show loading indicator
           }

           if (event instanceof NavigationEnd && window.innerWidth < 1025) {
               this.toggleSidebarClass();
           }

           if (event instanceof NavigationError) {
               console.error(event?.error);
           }
        });

        //this.preferencesService.privacyMode

        // this.preferencesService.privacyModeChange.subscribe(
        //     value => this.navHeader = this.getSidebarLinks(value),
        // );
    }

    public ngOnInit(): void
    {
        this.themeService.theme.subscribe((theme: 'light' | 'dark') => {
            if (this.currentTheme) {
                this.renderer.removeClass(this.document.documentElement, `${this.currentTheme}-theme`);
                this.renderer.setAttribute(this.document.documentElement, 'data-bs-theme', theme);
            }

            this.currentTheme = theme;

            this.renderer.addClass(this.document.documentElement, `${theme}-theme`);
        });

        // TODO: Convert to the angular way
        // $('.toggle-icon').click(() => {
        //     if ($('.wrapper').hasClass('toggled')) {
        //         // unpin sidebar when hovered
        //         $('.wrapper').removeClass('toggled');
        //         $('.sidebar-wrapper').unbind('hover');
        //     } else {
        //         $('.wrapper').addClass('toggled');
        //         $('.sidebar-wrapper').hover(() => {
        //             $('.wrapper').addClass('sidebar-hovered');
        //         }, () => {
        //             $('.wrapper').removeClass('sidebar-hovered');
        //         });
        //     }
        // });
    }


    public togglePrivacyMode(value: boolean): void
    {
        // this.preferencesService.privacyMode = value;
    }

    public toggleTheme(): void
    {
        this.themeService.toggleTheme();
    }

    public toggleSidebarClass(): void
    {
        const wrapper = this.elementRef.nativeElement.parentNode.parentNode;

        if (wrapper.classList.contains('toggled')) {
            this.renderer.removeClass(wrapper, 'toggled');
        } else {
            this.renderer.addClass(wrapper, 'toggled');
        }

        this.toggleClass = this.toggleClass ? null : 'toggled';
    }

    public expandSubMenu(item: RouteInfo, index: number): void
    {
        this.menuItems.forEach((menuItem: RouteInfo) => {
            if (menuItem !== item && menuItem.id !== item.parentId) {
                menuItem.expanded = false;
            }
        });

        if (item.submenu) {
            item.expanded = !item.expanded;
        }
    }

    private getSidebarLinks(privacyMode: boolean): RouteInfo[]
    {
        return [
            {
                id: '01j8fp3dwgaenj2phdf1yf35wf',
                title: 'Dashboard',
                path: 'dashboard',
                icon: 'fa-address-card',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: true
            },
            {
                id: '01j8fp3dwgyjcepfehd65v7t99',
                title: 'Leads',
                path: 'leads',
                icon: 'fa-user-plus',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: true
            },
            {
                id: '01j8fp3dwgjrde926p8t7qppy5',
                title: 'Bookings',
                path: 'bookings',
                icon: 'fa-calendar-alt',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                active: true
            },
            {
                id: '01j8fp3dwga0wkga83t9naxaq5',
                title: 'Vehicles',
                path: 'vehicle',
                queryParams: {status_id: VehicleStatuses.InStock},
                icon: 'fa-car-side',
                class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
                active: true,
                submenu: [
                    {
                        id: '01j8fp3dwgrwfg7jpnchyf37mg',
                        title: 'All Vehicles',
                        path: 'vehicles',
                        queryParams: {status_id: ''},
                        icon: 'fa-arrow-right',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        active: true,
                        parentId: '01j8fp3dwga0wkga83t9naxaq5'
                    },
                    {
                        id: '01j8fp3dwg3vrvp7m7w29z8xde',
                        title: 'In Stock',
                        path: 'vehicles',
                        queryParams: {status_id: VehicleStatuses.InStock},
                        icon: 'fa-arrow-right',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        active: true,
                        parentId: '01j8fp3dwga0wkga83t9naxaq5'
                    },
                    {
                        id: '01j8fp3dwg9c8qzpve7z13qegm',
                        title: 'Ex Stock',
                        path: 'vehicles',
                        queryParams: {status_id: VehicleStatuses.ExStock},
                        icon: 'fa-arrow-right',
                        class: '', badge: '', badgeClass: '', isExternalLink: false,
                        active: true,
                        parentId: '01j8fp3dwga0wkga83t9naxaq5'
                    },
                    {
                        id: '01j8fp3dwgt1bmm3jt1tk2gxgd',
                        title: 'SOR Stock',
                        path: 'vehicles',
                        queryParams: {status_id: VehicleStatuses.SaleOrReturn},
                        icon: 'fa-arrow-right',
                        class: '', badge: '', badgeClass: '', isExternalLink: false,
                        active: true,
                        parentId: '01j8fp3dwga0wkga83t9naxaq5'
                    },
                    {
                        id: '01j8fp3dwgfftj0zc7akq61h7j',
                        title: 'Deposit Taken',
                        path: 'vehicles',
                        queryParams: {status_id: VehicleStatuses.DepositTaken},
                        icon: 'fa-arrow-right',
                        class: '', badge: '', badgeClass: '', isExternalLink: false,
                        active: true,
                        parentId: '01j8fp3dwga0wkga83t9naxaq5'
                    },
                    {
                        id: '01j8fp3dwgjeazyrbqbbbf247x',
                        title: 'Awaiting Stock',
                        path: 'vehicles',
                        queryParams: {status_id: VehicleStatuses.AwaitingStock},
                        icon: 'fa-arrow-right',
                        class: '', badge: '', badgeClass: '', isExternalLink: false,
                        active: true,
                        parentId: '01j8fp3dwga0wkga83t9naxaq5'
                    },
                ]
            },
            {
                id: '01j8fp3dwg09c6n7v2jzjsx0sg',
                title: 'Invoices',
                path: 'invoices',
                icon: 'fa-file-invoice',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: !privacyMode
            },
            {
                id: '01j8fp3dwgqn01s23sbbmp7hqh',
                title: 'Reports',
                path: 'reports',
                icon: 'fa-chart-bar',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: !privacyMode
            },
            {
                id: '01j8fp3dwghrf59eqkbm32yn8k',
                title: 'Contacts',
                path: 'contacts',
                icon: 'fa-address-card',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: !privacyMode
            },
            {
                id: '01j8fp3dwg1s5h8a9e5wwtbmpg',
                title: 'Users',
                path: 'users',
                queryParams: {status_id: [UserStatuses.Invited, UserStatuses.Active]},
                icon: 'fa-users',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: !privacyMode
            },
            {
                id: '01j8fp3dwgz39n4k28hj0wfkjb',
                title: 'Settings',
                path: 'settings',
                fragment: 'dealer-details',
                icon: 'fa-cogs',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: !privacyMode
            }
        ];
    }
}
